import clsx from 'clsx';

import Image from 'next/legacy/image';
import { PMPKeyVisualViewProps } from 'lib/themes/themes';

import styles from './PMPKeyVisualView.module.css';
import baseStyles from '../base.module.css';
import { getImageUrl } from 'lib/util/imageUrl';

const PMPKeyVisualView = ({
  bannerImageUrl,
  bannerTitle,
  bannerDescription,
}: PMPKeyVisualViewProps) => {
  return (
    <section className={styles['mainvisual']}>
      <Image
        priority={true}
        className={styles['mainvisual__pic']}
        layout="fill"
        src={bannerImageUrl}
        loader={({ src, width }) => getImageUrl(src, width)}
        alt="banner"
      />
      <div
        className={clsx(
          baseStyles['base-inner'],
          styles['mainvisual__desc'],
          baseStyles['color-txt--white']
        )}
      >
        <div className={styles['title']}>{bannerTitle}</div>
        <span className={baseStyles['color-bg--white']}></span>
        <p>{bannerDescription}</p>
      </div>
      <p className={clsx(styles['mainvisual__scroll'], baseStyles['color-txt--white'])}>
        <p className={styles['mainvisual__scroll__txt']}>SCROLL</p>
        <span></span>
      </p>
    </section>
  );
};

export default PMPKeyVisualView;
